// assets
import { UserAddOutlined, WalletOutlined } from "@ant-design/icons";

// icons
const icons = {
  UserAddOutlined,
  WalletOutlined,
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = {
  id: "masterdata",
  title: "Master Data",
  type: "group",
  children: [
    {
      id: "user",
      title: "User",
      type: "item",
      url: "user",
      icon: icons.UserAddOutlined,
    },
    {
      id: "account",
      title: "Account",
      type: "item",
      url: "main-account",
      icon: icons.WalletOutlined,
    },
    // {
    //   id: "budgeting",
    //   title: "Budgeting",
    //   type: "item",
    //   url: "https://codedthemes.gitbook.io/mantis-react/",
    //   icon: icons.QuestionOutlined,
    //   external: true,
    //   target: true,
    // },
  ],
};

export default support;
