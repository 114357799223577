import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));

const UserPage = Loadable(lazy(() => import("pages/masterdata/User/User")));
const UserForm = Loadable(lazy(() => import("pages/masterdata/User/UserForm")));
const MainAccount = Loadable(
  lazy(() => import("pages/masterdata/MainAccount/Account"))
);
const SecondAccountList = Loadable(
  lazy(() => import("pages/masterdata/SecondAccount/Account"))
);
const SecondAccountForm = Loadable(
  lazy(() => import("pages/masterdata/SecondAccount/AccountForm"))
);
const ThirdAccountList = Loadable(
  lazy(() => import("pages/masterdata/ThirdAccount/Account"))
);
const ThirdAccountForm = Loadable(
  lazy(() => import("pages/masterdata/ThirdAccount/AccountForm"))
);
const FourthAccountList = Loadable(
  lazy(() => import("pages/masterdata/FourthAccount/Account"))
);
const FourthAccountForm = Loadable(
  lazy(() => import("pages/masterdata/FourthAccount/AccountForm"))
);
const FifthAccountList = Loadable(
  lazy(() => import("pages/masterdata/FifthAccount/Account"))
);
const FifthAccountForm = Loadable(
  lazy(() => import("pages/masterdata/FifthAccount/AccountForm"))
);
const SixAccountList = Loadable(
  lazy(() => import("pages/masterdata/SixAccount/Account"))
);
const SixAccountForm = Loadable(
  lazy(() => import("pages/masterdata/SixAccount/AccountForm"))
);
const SevenAccountList = Loadable(
  lazy(() => import("pages/masterdata/SevenAccount/Account"))
);
const SevenAccountForm = Loadable(
  lazy(() => import("pages/masterdata/SevenAccount/AccountForm"))
);
const EightAccountList = Loadable(
  lazy(() => import("pages/masterdata/EightAccount/Account"))
);
const EightAccountForm = Loadable(
  lazy(() => import("pages/masterdata/EightAccount/AccountForm"))
);
const MainAccountForm = Loadable(
  lazy(() => import("pages/masterdata/MainAccount/AccountForm"))
);

const UsedAmountList = Loadable(
  lazy(() => import("pages/masterdata/UsedAmount/UsedAmountList"))
);

// render - utilities
const Typography = Loadable(
  lazy(() => import("pages/components-overview/Typography"))
);
const Color = Loadable(lazy(() => import("pages/components-overview/Color")));
const Shadow = Loadable(lazy(() => import("pages/components-overview/Shadow")));
const AntIcons = Loadable(
  lazy(() => import("pages/components-overview/AntIcons"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/main",
  element: <MainLayout />,
  children: [
    {
      path: "/main",
      element: <DashboardDefault />,
    },
    {
      path: "color",
      element: <Color />,
    },
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: "user",
      element: <UserPage />,
    },
    {
      path: "user/new",
      element: <UserForm />,
    },
    {
      path: "user/edit/:idUser",
      element: <UserForm />,
    },
    {
      path: "main-account",
      element: <MainAccount />,
    },
    {
      path: "main-account/new",
      element: <MainAccountForm />,
    },
    {
      path: "main-account/edit/:id",
      element: <MainAccountForm />,
    },
    {
      path: "second-account/:id",
      element: <SecondAccountList />,
    },
    {
      path: "second-account/edit/:id",
      element: <SecondAccountForm />,
    },
    {
      path: "second-account/new",
      element: <SecondAccountForm />,
    },
    {
      path: "third-account/:id",
      element: <ThirdAccountList />,
    },
    {
      path: "third-account/edit/:id",
      element: <ThirdAccountForm />,
    },
    {
      path: "third-account/new",
      element: <ThirdAccountForm />,
    },
    {
      path: "fourth-account/:id",
      element: <FourthAccountList />,
    },
    {
      path: "fourth-account/edit/:id",
      element: <FourthAccountForm />,
    },
    {
      path: "fourth-account/new",
      element: <FourthAccountForm />,
    },
    {
      path: "fifth-account/:id",
      element: <FifthAccountList />,
    },
    {
      path: "fifth-account/edit/:id",
      element: <FifthAccountForm />,
    },
    {
      path: "fifth-account/new",
      element: <FifthAccountForm />,
    },
    {
      path: "six-account/:id",
      element: <SixAccountList />,
    },
    {
      path: "six-account/edit/:id",
      element: <SixAccountForm />,
    },
    {
      path: "six-account/new",
      element: <SixAccountForm />,
    },
    {
      path: "seven-account/:id",
      element: <SevenAccountList />,
    },
    {
      path: "seven-account/edit/:id",
      element: <SevenAccountForm />,
    },
    {
      path: "seven-account/new",
      element: <SevenAccountForm />,
    },
    {
      path: "eight-account/:id",
      element: <EightAccountList />,
    },
    {
      path: "eight-account/edit/:id",
      element: <EightAccountForm />,
    },
    {
      path: "eight-account/new",
      element: <EightAccountForm />,
    },
    {
      path: "used-amount/:id/list",
      element: <UsedAmountList />,
    },
    {
      path: "shadow",
      element: <Shadow />,
    },
    {
      path: "typography",
      element: <Typography />,
    },
    {
      path: "icons/ant",
      element: <AntIcons />,
    },
  ],
};

export default MainRoutes;
