import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const allMainAccount = atom({
  key: "allMainAccount",
  default: {},
  effects: [persistAtom],
});

export const allSecondAccount = atom({
  key: "allSecondAccount",
  default: {},
  effects: [persistAtom],
});
export const allThirdAccount = atom({
  key: "allThirdAccount",
  default: {},
  effects: [persistAtom],
});
export const allFourthAccount = atom({
  key: "allFourthAccount",
  default: {},
  effects: [persistAtom],
});
export const allFifthAccount = atom({
  key: "allFifthAccount",
  default: {},
  effects: [persistAtom],
});
export const allSixAccount = atom({
  key: "allSixAccount",
  default: {},
  effects: [persistAtom],
});
export const allSevenAccount = atom({
  key: "allSevenAccount",
  default: {},
  effects: [persistAtom],
});

export const allEightAccount = atom({
  key: "allEightAccount",
  default: {},
  effects: [persistAtom],
});
export const mainAccountState = atom({
  key: "mainAccountState",
  default: "",
  effects: [persistAtom],
});

export const secondAccountState = atom({
  key: "secondAccountState",
  default: "",
  effects: [persistAtom],
});

export const thirdAccountState = atom({
  key: "thirdAccountState",
  default: "",
  effects: [persistAtom],
});

export const fourthAccountState = atom({
  key: "fourthAccountState",
  default: "",
  effects: [persistAtom],
});

export const fifthAccountState = atom({
  key: "fifthAccountState",
  default: "",
  effects: [persistAtom],
});

export const sixAccountState = atom({
  key: "sixAccountState",
  default: "",
  effects: [persistAtom],
});

export const sevenAccountState = atom({
  key: "sevenAccountState",
  default: "",
  effects: [persistAtom],
});

export const allCodeState = atom({
  key: "allCodeState",
  default: "",
  effects: [persistAtom],
});

export const allUsedAmount = atom({
  key: "allUsedAmount",
  default: "",
  effects: [persistAtom],
});

export const userDetail = atom({
  key: "userDetail",
  default: {},
  effects: [persistAtom],
});
