// project import
// import pages from "./pages";
import dashboard from "./dashboard";
// import utilities from "./utilities";
// import support from "./support";
import masterdata from "./masterdata";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, masterdata],
};

export default menuItems;
