import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// scroll bar
import "simplebar/src/simplebar.css";
import "style.css";
// third-party
import { Provider as ReduxProvider } from "react-redux";

// apex-chart
import "assets/third-party/apex-chart.css";

// project import
import App from "./App";
import { store } from "store";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import CheckLoginUser from "utils/CheckUserLogin";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <RecoilRoot>
          <BrowserRouter basename="/">
            <CheckLoginUser />
            <App />
          </BrowserRouter>
        </RecoilRoot>
      </ReduxProvider>
    </QueryClientProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
