import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userDetail } from "./GlobalRecoil";
import { useRecoilValue } from "recoil";

const CheckLoginUser = () => {
  const navigate = useNavigate("/");
  const userValue = useRecoilValue(userDetail);

  useEffect(() => {
    Object.keys(userValue).length === 0 && navigate("/");
  }, [userValue]);
};

export default CheckLoginUser;
